import { getEntities, reset } from '../../../../../app/entities/product/product.reducer';
import { getEntities as getUpsellCart } from '../../../../../app/entities/product-in-cart/product-in-cart.reducer';
import { createEntity } from '../home-page/product.reducer';
import { getEntity as getOrderDetails } from '../../../../../app/entities/order/order.reducer';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import React, { useEffect, useState, useRef } from 'react';
import { Storage } from 'react-jhipster';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Table } from 'reactstrap';

interface Props {}
export const Thankyou: React.FC = (props: Props) => {
  const slickRef = useRef(null);
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams<'id'>();

  const isNew = id === undefined;
  const product = useAppSelector(state => state.product.entities);
  const orderDetails = useAppSelector(state => state.order.entity);
  const loading = useAppSelector(state => state.productInCart.loading);
  const upSellProductsCart = useAppSelector(state => state.productInCart.entities);
  const orderId = parseInt(localStorage.getItem('orderId'));
  const totalPrice = parseInt(localStorage.getItem('totalPrice'));
  const addressId = parseInt(localStorage.getItem('addressId'));
  const checkUpsellProduct = JSON.parse(localStorage.getItem('checkUpsellProduct'));
  const [quantityOfProduct, setQuantityOfProduct] = useState({});
  const [currentSlide, setCurrentSlide] = useState(0);
  const [hideUpsell, setHideUpsell] = useState(true);
  const [upSellCart, setUpSellCart] = useState();
  const [orderDetailsQuantity, setOrderDetailsQuantity] = useState(0);
  useEffect(() => {
    // debugger
    if (isNaN(orderId) == false) {
      dispatch(getOrderDetails(orderId));
    }
    if (window) {
      window.onbeforeunload = function () {
        localStorage.clear();
      };
    }
  }, []);

  const getEmailAdress = orderDetails?.addresses?.map(item => {
    return item.id == addressId ? item.email : '';
  });
  const calculateTotal = (orderDetails, propName) => {
    if (orderDetails?.payments?.length == 0) {
      return orderDetails?.totalAmount?.toFixed(2);
    }
    const payments = orderDetails?.payments || [];
    const values = payments.map(obj => obj?.[propName] || 0);

    const total = values.reduce((a, b) => a + b, 0);
    return total > 0 ? total.toFixed(2) : '0.00';
  };
  let getDateDetails = new Date();
  let todayDate = getDateDetails.getMonth() + 1 + '-' + getDateDetails.getDate() + '-' + getDateDetails.getFullYear();

  return (
    <>
      {/* <!--  testimonial section start --> */}
      {orderDetails == undefined || isNaN(orderId) ? (
        <section id="center" className="center_slide1 product_design py-5 " style={{ height: 'calc(100vh - 395px)' }}>
          <div className="thank-heading text-center">
            {' '}
            <div className="display-4 text-center fw-bold mb-1"> Not Allowed </div>
          </div>
        </section>
      ) : (
        <section
          id="center"
          className="center_slide1 product_design py-5 container"
          // style={product.length == 0  ? { height: 'calc(100vh - 395px)' } : {}}
        >
          <div className="thank-heading text-center">
            {/* <img src="content/images/check.png" /> */}
            {/* <div className="display-4 text-center fw-bold mb-1"> Thank You</div> */}
            <p className="fs-5 mb-0">
              <strong>Order Number Is :</strong> {`#${orderDetails?.orderNumber || orderDetails?.id}`}
            </p>
            <p className="fs-6">Before you go, check out these exclusive customer offers!</p>
          </div>

          <Table
            // width="100%"
            style={{
              fontFamily: '"Montserrat", sans-serif',
              borderCollapse: 'collapse',
              backgroundColor: '#efefef',
              maxWidth: '600px',
              margin: '0 auto',
              width: '100%',
            }}
            cellSpacing={0}
            cellPadding={0}
            border={0}
            className="main"
          >
            <tbody>
              <tr>
                <td>
                  <Table className="order-details" width="100%" cellSpacing={0} cellPadding={0}>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: '0',
                          }}
                        >
                          <div
                            style={{
                              backgroundColor: '#F0F0F0',
                              width: '100%',
                              padding: '1px 0px',
                              backgroundRepeat: 'no-repeat',
                              backgroundSize: 'cover',
                              backgroundPosition: 'center',
                              boxSizing: 'border-box',
                            }}
                          >
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                marginBottom: 0,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                              }}
                            >
                              <b>Please check your order details below:</b>
                            </p>
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                marginTop: 0,
                                marginBottom: 0,
                              }}
                            >
                              Order Date:<b> {todayDate}</b>
                            </p>
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                marginTop: 0,
                              }}
                            >
                              Order Total: <b> ${calculateTotal(orderDetails, 'paymentAmount')}</b>
                            </p>
                            <p
                              style={{
                                borderBottom: '1px solid #ddd',
                                paddingBottom: '10px',
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                              }}
                            >
                              You will receive an email confirmation shortly and your tracking number will be sent to:
                              <b>{getEmailAdress}</b>
                            </p>
                            <p
                              style={{
                                fontStyle: 'normal',
                                fontWeight: 300,
                                fontSize: '14px',
                                lineHeight: '30px',
                                color: '#000000',
                                padding: '15px 10px',
                                background: '#ddd',
                              }}
                            >
                              Orders can take up to 7-10 days to deliver, but may take longer due to high demand. We will send you a
                              tracking email once your order has dispatched.
                            </p>
                            <Table width="100%" cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr className="address-details">
                                  {orderDetails &&
                                    orderDetails?.addresses &&
                                    orderDetails?.addresses.map(item => (
                                      <td style={{ border: 0, width: '50%' }}>
                                        <div
                                          style={{
                                            background: '#ddd',
                                            padding: '15px',
                                          }}
                                        >
                                          {' '}
                                          <b
                                            style={{
                                              fontStyle: 'normal',
                                              fontWeight: 'bold',
                                              fontSize: '14px',
                                              lineHeight: '30px',
                                              color: '#000000',
                                            }}
                                          >
                                            {item.type}
                                          </b>
                                          <p
                                            style={{
                                              fontStyle: 'normal',
                                              fontWeight: 300,
                                              fontSize: '14px',
                                              lineHeight: '30px',
                                              color: '#000000',
                                              margin: 0,
                                            }}
                                          >
                                            {item.firstName + '' + item.lastName}
                                          </p>
                                          <p
                                            style={{
                                              fontStyle: 'normal',
                                              fontWeight: 300,
                                              fontSize: '14px',
                                              lineHeight: '30px',
                                              color: '#000000',
                                              margin: 0,
                                            }}
                                          >
                                            {item.street}
                                          </p>
                                          {item.street2 && (
                                            <p
                                              style={{
                                                fontStyle: 'normal',
                                                fontWeight: 300,
                                                marginTop: 0,
                                                fontSize: '14px',
                                                lineHeight: '30px',
                                                color: '#000000',
                                                margin: 0,
                                              }}
                                            >
                                              {item.street2}
                                            </p>
                                          )}
                                          <p
                                            style={{
                                              fontStyle: 'normal',
                                              fontWeight: 300,
                                              marginTop: 0,
                                              fontSize: '14px',
                                              lineHeight: '30px',
                                              color: '#000000',
                                              margin: 0,
                                            }}
                                          >
                                            {item.city + ' ' + item.state + ' ' + item.countryCode}
                                          </p>
                                          <p
                                            style={{
                                              fontStyle: 'normal',
                                              fontWeight: 300,
                                              marginTop: 0,
                                              fontSize: '14px',
                                              lineHeight: '30px',
                                              color: '#000000',
                                              margin: 0,
                                            }}
                                          >
                                            {item.contact}
                                          </p>
                                        </div>
                                      </td>
                                    ))}
                                </tr>
                              </tbody>
                            </Table>
                            {orderDetails && orderDetails?.productInCarts && orderDetails?.productInCarts.length > 0 ? (
                              <>
                                <p
                                  className="item-order"
                                  style={{
                                    fontStyle: 'normal',
                                    fontWeight: 300,
                                    fontSize: '14px',
                                    lineHeight: '30px',
                                    color: '#000000',
                                    padding: '15px 10px',
                                    background: '#ddd',
                                  }}
                                >
                                  <b>ITEMS ORDERED</b>
                                </p>
                                <Table
                                  style={{
                                    borderColor: '#f2f2f2',
                                    margin: '20px auto 0px',
                                    width: '100%',
                                  }}
                                  cellSpacing={0}
                                  cellPadding={0}
                                  border={0}
                                  align="center"
                                >
                                  <tbody>
                                    <tr>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          borderBottom: '1px solid #ddd',
                                          fontSize: '14px',
                                          textAlign: 'left',
                                          color: '#333',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <b>
                                          Item Name
                                          <b />
                                        </b>
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          fontSize: '14px',
                                          borderBottom: '1px solid #ddd',
                                          textAlign: 'right',
                                          color: '#333',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <b>
                                          Quantity
                                          <b />
                                        </b>
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          fontSize: '14px',
                                          borderBottom: '1px solid #ddd',
                                          textAlign: 'right',
                                          color: '#333',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <b>
                                          Price
                                          <b />
                                        </b>
                                      </td>
                                    </tr>
                                    {orderDetails &&
                                      orderDetails?.productInCarts &&
                                      orderDetails?.productInCarts.map(productInCarts => (
                                        <tr>
                                          <td
                                            style={{
                                              fontWeight: 500,
                                              borderBottom: '1px solid #ddd',
                                              fontSize: '14px',
                                              textAlign: 'left',
                                              color: '#333',
                                              padding: '5px',
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            {productInCarts.product.name}($
                                            {productInCarts.price})
                                          </td>
                                          <td
                                            style={{
                                              fontWeight: 500,
                                              fontSize: '14px',
                                              borderBottom: '1px solid #ddd',
                                              textAlign: 'center',
                                              color: '#333',
                                              padding: '5px',
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            <b>
                                              {productInCarts.quantity}
                                              <b />
                                            </b>
                                          </td>
                                          <td
                                            style={{
                                              fontWeight: 500,
                                              fontSize: '14px',
                                              borderBottom: '1px solid #ddd',
                                              textAlign: 'right',
                                              color: '#333',
                                              padding: '5px',
                                              textTransform: 'capitalize',
                                            }}
                                          >
                                            <b>
                                              ${productInCarts.price * productInCarts.quantity}
                                              <b />
                                            </b>
                                          </td>
                                        </tr>
                                      ))}
                                    <tr>
                                      <td colSpan={2} style={{ padding: '0px', border: 0 }} />
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          fontSize: '14px',
                                          borderBottom: '1px solid #ddd',
                                          textAlign: 'left',
                                          color: '#333',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        Shipping
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          fontSize: '14px',
                                          borderBottom: '1px solid #ddd',
                                          textAlign: 'right',
                                          color: '#333',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <b>
                                          {}
                                          <b />
                                        </b>
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          textAlign: 'right',
                                          borderBottom: '1px solid #ddd',
                                          fontSize: '14px',
                                          color: '#333',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <b>
                                          ${calculateTotal(orderDetails, 'shippingCharge')}
                                          <b />
                                        </b>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2} style={{ padding: '0px', border: 0 }} />
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          fontSize: '14px',
                                          borderBottom: '1px solid #ddd',
                                          textAlign: 'left',
                                          color: '#333',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        Tax
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          fontSize: '14px',
                                          borderBottom: '1px solid #ddd',
                                          textAlign: 'right',
                                          color: '#333',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <b>
                                          <b />
                                        </b>
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          textAlign: 'right',
                                          borderBottom: '1px solid #ddd',
                                          fontSize: '14px',
                                          color: '#333',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <b>${calculateTotal(orderDetails, 'tax')}</b>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td colSpan={2} style={{ padding: '0px', border: 0 }} />
                                    </tr>
                                    <tr>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          fontSize: '14px',
                                          borderBottom: '1px solid #ddd',
                                          textAlign: 'left',
                                          color: '#162f70',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <b>Total</b>
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          fontSize: '14px',
                                          borderBottom: '1px solid #ddd',
                                          textAlign: 'right',
                                          color: '#333',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <b>
                                          <b />
                                        </b>
                                      </td>
                                      <td
                                        style={{
                                          fontWeight: 500,
                                          textAlign: 'right',
                                          borderBottom: '1px solid #ddd',
                                          fontSize: '14px',
                                          color: '#162f70',
                                          padding: '5px',
                                          textTransform: 'capitalize',
                                        }}
                                      >
                                        <b>${calculateTotal(orderDetails, 'paymentAmount')}</b>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>

                                <Table width="100%" cellSpacing={0} cellPadding={0} style={{ paddingTop: '20px' }}>
                                  <tbody>
                                    <tr>
                                      <td>
                                        <div style={{ marginBottom: '30px' }}>
                                          <p
                                            style={{
                                              marginBottom: 0,
                                              fontStyle: 'normal',
                                              fontWeight: 'bold',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                              color: '#000000',
                                            }}
                                          >
                                            Thank You
                                          </p>
                                          <p
                                            style={{
                                              marginTop: 0,
                                              fontStyle: 'normal',
                                              fontSize: '14px',
                                              lineHeight: '20px',
                                              color: '#000000',
                                            }}
                                          >
                                            Secure Web Payments
                                          </p>
                                        </div>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </>
                            ) : (
                              ''
                            )}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
        </section>
      )}

      {/* <!--  testimonial section start --> */}
    </>
  );
};

export default Thankyou;
