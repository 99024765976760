import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import React, { useEffect, useState } from 'react';
import { getEntities, reset, deleteEntity, updateEntity } from '../../../../../app/entities/product-in-cart/product-in-cart.reducer';
import { createEntity } from './checkout.reducer';
import { Link, useNavigate } from 'react-router-dom';
import { getEntity as getAddress } from '../../../../../app/entities/address/address.reducer';
// import { createEntity as createTax } from './tax.reducer';
import { State, City } from 'country-state-city';
import { AddressType } from '../../../../../app/shared/model/enumerations/address-type.model';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
// import ReactPixel from 'react-facebook-pixel';
// import ReactGA from 'react-ga';
import valid, { number } from 'card-validator';
import 'bootstrap/dist/css/bootstrap.min.css';
import { animateScroll as scroll } from 'react-scroll';
import { Link as ScrollLink, Element } from 'react-scroll';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import './checkout.css';

interface Props {
  cartId?: any;
  hideItems?: boolean;
  check?: number;
}

export const Checkout = (props: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [cvvLength, setCvvLength] = useState(3);
  const [cardLength, setCardLength] = useState(16);
  const [errorMessage, setErrorMessage] = useState('');
  const [errorShippingAddress, setErrorShippingAddress] = useState('');
  const [errorBillingAddress, setErrorBillingAddress] = useState('');
  const porpsCartId = localStorage.getItem('cartId');
  // const propsCheck = props.check;
  const [cartId, setCartId] = useState(porpsCartId);
  // const [check, setCheck] = useState(propsCheck);
  const [hideItems, setHideItems] = useState(props.hideItems);
  const [billingaddress, setBillingaddress] = useState({
    type: AddressType.BillingAddress,
    firstName: '',
    lastName: '',
    email: '',
    street: '',
    street2: '',
    city: '',
    countryCode: 'US',
    postalCode: '',
    state: '',
    contact: '',
  });
  const [addressDetails, setAddressDetails] = useState({
    type: AddressType.ShippingAddress,
    firstName: '',
    lastName: '',
    email: '',
    street: '',
    street2: '',
    city: '',
    countryCode: 'US',
    postalCode: '',
    state: '',
    contact: '',
  });
  const [checkBillingAddress, setCheckBillingAddress] = useState(props?.hideItems ? false : true);
  const [subTotalPrice, setSubTotalPrice] = useState(0.0);
  const [grandTotal, setGrandTotal] = useState(0.0);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [priceDetails, setPriceDetails] = useState({ discount: 0, shipping: 14.99, tax: 0 });
  const [cardDetails, setCardDetails] = useState({
    first_name: '',
    last_name: '',
    number: '',
    month: '',
    year: '',
    verification_value: '',
  });
  const [checkUpsellProduct, setCheckUpsellProduct] = useState(true);
  const [quantityOfProduct, setQuantityOfProduct] = useState({});
  const productInCart = useAppSelector(state => state.productInCart.entities);
  const updatingCheckout = useAppSelector(state => state.productInCart?.updating || false);
  const updateSuccessProductInCart = useAppSelector(state => state.productInCart?.updateSuccess);
  const stateData = State.getStatesOfCountry('US');
  const cityData = City.getCitiesOfState('US', addressDetails['state']);
  const BillingCityData = City.getCitiesOfState('US', billingaddress['state']);
  // const cartId = props.cartId || parseInt(localStorage.getItem('cartId'));
  const addressId = parseInt(localStorage?.getItem('addressId'));
  const billingId = parseInt(localStorage?.getItem('billingId'));

  // useEffect(() => {
  //   debugger
  //   setCartId(props.cartId || localStorage.getItem('cartId'));
  //   setCheck(props.check);
  // }, [props.check]);

  // Check Time for checkout
  const checkTimeOut = fetchData => {
    if (fetchData?.checkoutDateTime) {
      const prevCheckoutDateTime = new Date(fetchData?.checkoutDateTime);
      const currentDatetime = new Date();
      const timeDifference = currentDatetime.getTime() - prevCheckoutDateTime.getTime();
      if (timeDifference <= 600000) {
        return true;
      } else {
        localStorage.removeItem('allCardDetails');
        return false;
      }
    }
  };

  useEffect(() => {
    localStorage.setItem('checkBillingAddress', JSON.stringify(checkBillingAddress));
  }, [checkBillingAddress]);

  useEffect(() => {
    setHideItems(props.hideItems || false);
    if (props?.hideItems) {
      setCheckBillingAddress(true);
      localStorage.setItem('checkBillingAddress', 'true');
    }
  }, [props.hideItems]);

  const createOrder = (data, actions) => {
    const getGrandTotal = parseFloat(localStorage.getItem('grandTotal'));
    const getAddressDetails = JSON.parse(localStorage.getItem('addressDetails'));
    const checkBillingDetails = JSON.parse(localStorage.getItem('checkBillingAddress'));
    const getBillingAddress = JSON.parse(localStorage.getItem('billingDetails'));

    const isValidAddress = address =>
      address?.firstName &&
      address?.lastName &&
      address?.email &&
      address?.street &&
      address?.state &&
      address?.city &&
      address?.postalCode &&
      address?.contact;

    if (getAddressDetails && isValidAddress(getAddressDetails)) {
      if (checkBillingDetails || (getBillingAddress && isValidAddress(getBillingAddress))) {
        if (getGrandTotal) {
          return actions.order.create({
            intent: 'authorize',
            purchase_units: [
              {
                amount: {
                  value: getGrandTotal,
                },
              },
            ],
          });
        }
      }
    }
  };

  const ValidationForm = (data, actions) => {
    const getAddressDetails = JSON.parse(localStorage.getItem('addressDetails'));
    const checkBillingDetails = JSON.parse(localStorage.getItem('checkBillingAddress'));
    const getBillingAddress = JSON.parse(localStorage.getItem('billingDetails'));

    const checkAddress = (address, section, errorMessage) => {
      if (
        address?.firstName &&
        address?.lastName &&
        address?.email &&
        address?.street &&
        address?.state &&
        address?.city &&
        address?.postalCode &&
        address?.contact
      ) {
        return true;
      } else {
        const options = {
          activeClass: 'active',
          to: section,
          smooth: true,
        };
        if (!hideItems) {
          const scrollOffset = window.screen.width < 769 ? (section === 'shipping' ? 300 : 1200) : section === 'shipping' ? 100 : 600;
          scroll.scrollTo(scrollOffset, options);
        }
        errorMessage(`Please enter ${section} address details`);
        return false;
      }
    };

    if (!checkAddress(getAddressDetails, 'shipping', setErrorShippingAddress)) {
      return;
    }

    if (!checkBillingDetails) {
      if (!checkAddress(getBillingAddress, 'billing', setErrorBillingAddress)) {
        return;
      }
    }
  };

  const onApprove = (data, actions) => {
    return actions.order.authorize().then(function (authorization) {
      var authorizationID = authorization.purchase_units[0].payments.authorizations[0].id;

      checkoutPayment(authorizationID);
    });
  };

  useEffect(() => {
    // ReactPixel.init('1729701367468489');
    // ReactPixel.pageView();
    // ReactGA.initialize('G-9GECVX94CM');
    // if (window && window.screen.width < 769) {
    //   document.getElementById('hideorderdetails').click();
    // }
    const fetchData = JSON.parse(localStorage.getItem('allCardDetails') || '{}');
    // const checkoutTime = checkTimeOut(fetchData);
    // if (checkoutTime && fetchData.first_name) {
    //   setCardDetails(prevValues => {
    //     return { ...prevValues, first_name: fetchData?.first_name + ' ' + fetchData?.last_name || '', number: fetchData?.number || '' };
    //   });
    //   let result = valid.number(fetchData?.number) as any;
    //   if (result.isValid) {
    //     setCvvLength(result.card.code.size);
    //     setCardLength(result.card.lengths[0]);
    //     setErrorMessage('');
    //   } else {
    //     setErrorMessage('Invalid Card number');
    //   }
    // }
  }, []);

  useEffect(() => {
    if (window && window.screen.width < 769) {
      document.getElementById('billingDetails') ? document.getElementById('billingDetails').click() : '';
    }
  }, [productInCart]);

  useEffect(() => {
    fetchAddressData();
    if (cartId || localStorage.getItem('cartId')) {
      dispatch(getEntities({ page: '0', size: '100', sort: `id&cartId.equals=${cartId || localStorage.getItem('cartId')}` }));
    } else {
      dispatch(reset());
    }
    if (updateSuccessProductInCart) {
      dispatch(getEntities({ page: '0', size: '100', sort: `id&cartId.equals=${cartId || localStorage.getItem('cartId')}` }));
    }
  }, [updateSuccessProductInCart, cartId]);
  const fetchAddressData = async () => {
    if (addressId) {
      const { payload } = await dispatch(getAddress(addressId));
      if (payload) {
        let addressData = { ...payload['data'] };
        delete addressData['order'];
        localStorage.setItem('addressDetails', JSON.stringify(addressData));
        setAddressDetails(addressData);
      }
    }
    if (billingId) {
      const { payload } = await dispatch(getAddress(billingId));
      if (payload) {
        let billingData = { ...payload['data'] };
        delete billingData['order'];
        localStorage.setItem('billingDetails', JSON.stringify(billingData));
        setBillingaddress(billingData);
      }
    }
  };

  const handleQuantityChange = async (productId, change, saveQuantity) => {
    setQuantityOfProduct(prevQuantities => {
      const updatedQuantity = (prevQuantities[productId] || saveQuantity) + change;
      // Apply quantity constraints
      const constrainedQuantity = Math.min(Math.max(updatedQuantity, 1), 5);
      let productInCartObj = productInCart.filter(item => item.id == productId)[0];
      productInCartObj = { ...productInCartObj, quantity: constrainedQuantity };
      dispatch(updateEntity(productInCartObj));

      return {
        ...prevQuantities,
        [productId]: constrainedQuantity,
      };
    });
  };
  // useEffect(() => {
  //   getTaxAmount();
  // }, [addressDetails]);
  // const getTaxAmount = async () => {
  //   if (
  //     addressDetails.countryCode != '' &&
  //     addressDetails.postalCode != '' &&
  //     addressDetails.state != '' &&
  //     addressDetails.city != '' &&
  //     addressDetails.street != ''
  //   ) {
  //     let addressObject = {
  //       amount: subTotalPrice,
  //       shipping: subTotalPrice > 0 && (addressDetails.state == 'AK' || addressDetails.state == 'HI') ? priceDetails.shipping : 0,
  //       to_country: addressDetails.countryCode,
  //       to_zip: addressDetails.postalCode,
  //       to_state: addressDetails.state,
  //       to_city: addressDetails.city,
  //       to_street: addressDetails.street,
  //     };
  //     const { payload } = await dispatch(createTax(addressObject));
  //     if (payload) {
  //       let taxAmount = payload['data'].tax;
  //       setPriceDetails({ ...priceDetails, tax: taxAmount.amount_to_collect });
  //       localStorage.setItem('taxAmount', taxAmount.amount_to_collect);
  //     } else {
  //       setPriceDetails({ ...priceDetails, tax: 0 });
  //     }
  //   }
  // };
  const handleBillingAddress = ({ target }) => {
    let { value, name } = target;

    let updateBillingDetails = { ...billingaddress };

    updateBillingDetails[name] = value;

    if (addressId) {
      delete updateBillingDetails['id'];
    }
    setErrorBillingAddress('');
    localStorage.setItem('billingDetails', JSON.stringify(updateBillingDetails));
    setBillingaddress(updateBillingDetails);
  };

  const handleAddressDetail = ({ target }) => {
    let { value, name } = target;

    // Validate phone number: allow only digits and limit to 12 digits
    if (name === 'contact') {
      value = value.replace(/\D/g, ''); // Remove non-digit characters
      value = value.slice(0, 12); // Limit to 12 digits
    }

    let updatedAddressDetails = { ...addressDetails };

    updatedAddressDetails[name] = value;

    if (addressId) {
      delete updatedAddressDetails['id'];
    }
    setErrorShippingAddress('');
    localStorage.setItem('addressDetails', JSON.stringify(updatedAddressDetails));
    setAddressDetails(updatedAddressDetails);
  };

  const handleCardDetails = ({ target }) => {
    const { value, name } = target;

    if (name === 'number' && value.length > 3) {
      let result = valid.number(value);
      if (result.isValid) {
        setCvvLength(result.card.code.size);
        setCardLength(result.card.lengths[0]);
        setErrorMessage('');
      } else {
        setErrorMessage('Invalid Card number');
      }
    }

    if (name === 'month') {
      // Ensure the value contains only digits and limit to 4 digits
      const sanitizedValue = value.replace(/\D/g, '').slice(0, 4);

      let result = valid.expirationDate(sanitizedValue);

      if (result.isValid) {
        setErrorMessage('');
      } else {
        setErrorMessage('Please enter Expiry date in MM/YY format');
      }

      setCardDetails(prevDetails => ({
        ...prevDetails,
        [name]: sanitizedValue,
      }));
    }

    if (name !== 'month') {
      setCardDetails(prevDetails => ({
        ...prevDetails,
        [name]: name === 'verification_value' ? value.replace(/\D/g, '') : value,
      }));
    }
  };

  const checkoutOrder = async e => {
    e.preventDefault();
    checkoutPayment();
  };

  const checkoutPayment = async (payPalAuth?: any) => {
    const cartId = parseInt(localStorage.getItem('cartId'));
    const orderId = parseInt(localStorage.getItem('orderId'));
    const getAddressDetails = JSON.parse(localStorage.getItem('addressDetails'));
    const getBillingDetails = JSON.parse(localStorage.getItem('billingDetails'));
    const getUpsellProduct = JSON.parse(localStorage.getItem('checkUpsellProduct'));

    let firstName = '',
      lastName = '';
    let name_arr = cardDetails.first_name.split(' ');
    firstName = name_arr
      .slice(0, name_arr.length - 1)
      .join(' ')
      .trim();
    lastName = name_arr[name_arr.length - 1].trim();

    let updateCardDetails = {
      ...cardDetails,
    };

    updateCardDetails['first_name'] = firstName;
    updateCardDetails['last_name'] = lastName;
    updateCardDetails['month'] = valid.expirationDate(cardDetails.month).month;
    updateCardDetails['year'] = new Date()
      .getFullYear()
      .toString()
      .split('')
      .slice(0, 2)
      .join('')
      .concat(valid.expirationDate(cardDetails.month).year);

    // let taxAmount = parseFloat(localStorage.getItem('taxAmount'));
    let getGrandTotal = parseFloat(localStorage.getItem('grandTotal'));
    let getSubTotalPrice = parseFloat(localStorage.getItem('totalPrice'));
    let checkoutDetails: any = {
      cartId: cartId,
      address: getAddressDetails,
      // taxAmount: taxAmount,
      shippingCharges: !getUpsellProduct
        ? getSubTotalPrice > 0 && (getAddressDetails.state == 'AK' || getAddressDetails.state == 'HI')
          ? priceDetails.shipping
          : 0
        : 0,
      totalAmount: getGrandTotal,
    };

    if (payPalAuth) {
      checkoutDetails['payment'] = { referenceCode: payPalAuth, paymentAmount: getGrandTotal };
      checkoutDetails['cardDetails'] = {};
    } else {
      checkoutDetails['payment'] = {};
      checkoutDetails['cardDetails'] = updateCardDetails;
    }

    if (orderId) {
      checkoutDetails['orderId'] = orderId;
    }

    if (checkBillingAddress) {
      let billingAddressUpadeType = getAddressDetails;
      if (getAddressDetails.id) {
        checkoutDetails['billingAddress'] = { ...billingAddressUpadeType, type: AddressType.BillingAddress, id: billingId };
      } else {
        checkoutDetails['billingAddress'] = { ...billingAddressUpadeType, type: AddressType.BillingAddress };
      }
    } else {
      checkoutDetails['billingAddress'] = getBillingDetails;
    }
    //Save in localstroage
    const currentDatetime = new Date();
    localStorage.setItem(
      'allCardDetails',
      JSON.stringify({
        first_name: firstName || '',
        last_name: lastName || '',
        number: checkoutDetails?.cardDetails?.number || '',
        checkoutDateTime: currentDatetime,
      })
    );

    const checkOutPayload = await dispatch(createEntity(checkoutDetails));

    const { payload, error } = checkOutPayload || checkOutPayload['error'];
    if (payload) {
      const { addresses, status, payments, id } = payload['data'];
      if (status === 'NEW') {
        const getAddressId = addresses.filter(item => item.type == AddressType.ShippingAddress)[0].id;
        const getBillingId = addresses.filter(item => item.type == AddressType.BillingAddress)[0].id;
        localStorage.removeItem('cartId');
        localStorage.removeItem('addressDetails');
        localStorage.removeItem('billingDetails');
        // localStorage.removeItem('taxAmount');
        localStorage.removeItem('grandTotal');

        if (id.toString() == localStorage.getItem('orderId')) {
          localStorage.setItem('hideUpsell', 'true');
        }
        localStorage.setItem('addressId', getAddressId);
        localStorage.setItem('billingId', getBillingId);
        localStorage.setItem('orderId', id);
        // localStorage.setItem('paymentReferenceCode', payments[0].referenceCode);
        document.cookie = 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
        navigate('/thankyou');
      }
    }
    if (error) {
      let errorResponse = error?.response?.data;
      setErrorMessage(errorResponse);
    }
  };

  const getImageUrl = imageObj => {
    if (imageObj.path != '') {
      return imageObj.path;
    } else {
      return `content/images/${imageObj.name}`;
    }
  };
  //remove product from cart
  const removeProduct = productId => {
    let productRemoveDetails = productInCart.filter(
      item => item.product.name.toLowerCase().indexOf('miracle smile') > -1 && item.id == productId
    )[0];
    let getExtendedWarranty = productInCart.filter(obj => obj.product.name.toLowerCase() == 'extended warranty')[0];
    if (productRemoveDetails && getExtendedWarranty) {
      let updateExtendedWarranty = getExtendedWarranty.quantity - productRemoveDetails.quantity;
      getExtendedWarranty = { ...getExtendedWarranty, quantity: updateExtendedWarranty };
      dispatch(updateEntity(getExtendedWarranty));
    }

    dispatch(deleteEntity(productId));
  };

  useEffect(() => {
    let subTotalPrices = productInCart && productInCart.map(obj => obj?.quantity * obj?.price || 0);
    subTotalPrices = subTotalPrices.length > 0 ? subTotalPrices.reduce((a, b) => a + b).toFixed(2) : 0;
    let { discount, shipping, tax } = priceDetails;
    setSubTotalPrice(parseFloat(subTotalPrices));
    let checkProductCategory =
      productInCart &&
      productInCart.map(obj => obj?.product?.productCategory?.name == 'upsell' || obj?.product?.productCategory?.name == 'quantity');
    checkProductCategory = checkProductCategory.includes(true);
    localStorage.setItem('checkUpsellProduct', checkProductCategory);
    setCheckUpsellProduct(checkProductCategory);

    let grandTotals = !checkProductCategory
      ? subTotalPrices > 0 && (addressDetails.state == 'AK' || addressDetails.state == 'HI')
        ? (parseFloat(subTotalPrices) + shipping + tax - discount).toFixed(2)
        : (parseFloat(subTotalPrices) + tax - discount).toFixed(2)
      : (parseFloat(subTotalPrices) + tax - discount).toFixed(2);
    setGrandTotal(parseFloat(grandTotals));

    localStorage.setItem('grandTotal', parseFloat(grandTotals).toString());
    localStorage.setItem('totalPrice', parseFloat(subTotalPrices).toString());
  }, [productInCart, grandTotal, subTotalPrice, priceDetails, addressDetails]);
  let { discount, shipping, tax } = priceDetails;
  return (
    <>
      {updatingCheckout && (
        <div className="loader">
          <img src="content/images/loader.gif" />   {' '}
        </div>
      )}
      {!hideItems && (
        <section className="cart-baner" id="cart-baner">
          <div className="container">
            <div className="row text-center">
              <div className="display-4 cart-heading fs-1 mb-5"> Checkout</div>
            </div>
          </div>
        </section>
      )}
      {/* // <!-- banner Section End--> */}
      <section className="checkout-section" id="checkout-section" style={{ maxWidth: '1200px', margin: 'auto' }}>
        <div className="container">
          <form onSubmit={checkoutOrder}>
            <div className="row">
              <div className="col-xl-8 col-md-12 flex-grow w-full lg:flex-grow lg:pt-6 lg:pr-16 lg:border-r lg:border-gray-300">
                <div className="contact-info mb-4">
                  <div className="contact-info-heading fs-5 pb-4 fw-bolder">Contact Info (Shipping Address)</div>

                  <div className="row" id="shipping">
                    {errorShippingAddress && (
                      <div style={{ color: 'red' }}>
                        <p>{errorShippingAddress}</p>
                      </div>
                    )}
                    <div className="col-md-6 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleAddressDetail}
                        name="firstName"
                        value={addressDetails.firstName}
                        id="firstName"
                        placeholder="First Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleAddressDetail}
                        value={addressDetails.lastName}
                        name="lastName"
                        id="lastName"
                        placeholder="Last Name"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <input
                        type="email"
                        className="form-control"
                        value={addressDetails.email}
                        name="email"
                        id="email"
                        placeholder="Email Address"
                        onChange={handleAddressDetail}
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleAddressDetail}
                        name="street"
                        value={addressDetails.street}
                        id="streetAddress"
                        placeholder="Street Address"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleAddressDetail}
                        name="street2"
                        value={addressDetails.street2}
                        id="other"
                        placeholder="Apt/Suite/Other"
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <select
                        className="custom-select  d-block w-100"
                        id="state"
                        value={addressDetails.state}
                        onChange={handleAddressDetail}
                        name="state"
                        required
                      >
                        <option value="">State/Province</option>
                        {stateData.map(state => (
                          <option value={state.isoCode}>{state.name}</option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-6 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        onChange={handleAddressDetail}
                        name="city"
                        value={addressDetails.city}
                        id="city"
                        placeholder="City"
                      />
                      {/* <select
                        className="custom-select  d-block w-100"
                        id="city"
                        value={addressDetails.city}
                        onChange={handleAddressDetail}
                        name="city"
                        required
                      >
                        <option value="">Select City</option>
                        {cityData.map(city => (
                          <option value={city.name}>{city.name}</option>
                        ))}
                      </select> */}
                    </div>
                    <div className="col-md-6 mb-3">
                      <input
                        type="text"
                        className="form-control"
                        id="postalcode"
                        value={addressDetails.postalCode}
                        onChange={handleAddressDetail}
                        name="postalCode"
                        placeholder="Zip/Postal Code"
                        required
                      />
                    </div>
                    <div className="col-md-6 mb-3">
                      <input
                        type="text"
                        required
                        onChange={handleAddressDetail}
                        className="form-control"
                        value={addressDetails.contact}
                        id="contact"
                        name="contact"
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="col-md-12 mb-3 pt-4">
                      <div className="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id="same-address"
                          checked={checkBillingAddress}
                          onChange={e => setCheckBillingAddress(!checkBillingAddress)}
                        />{' '}
                        <label className="custom-control-label fs-5">My billing and shipping address are the same</label>
                      </div>
                    </div>
                  </div>
                </div>
                {!checkBillingAddress && (
                  <div className="contact-info mb-4">
                    <div className="contact-info-heading fs-5 pb-4 fw-bolder">Billing Address</div>

                    <div className="row" id="billing">
                      {errorBillingAddress && (
                        <div style={{ color: 'red' }}>
                          <p>{errorBillingAddress}</p>
                        </div>
                      )}
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleBillingAddress}
                          name="firstName"
                          value={billingaddress.firstName}
                          id="firstName"
                          placeholder="First Name"
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleBillingAddress}
                          value={billingaddress.lastName}
                          name="lastName"
                          id="lastName"
                          placeholder="Last Name"
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="email"
                          className="form-control"
                          name="email"
                          id="email"
                          placeholder="Email Address"
                          onChange={handleBillingAddress}
                          value={billingaddress.email}
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleBillingAddress}
                          name="street"
                          value={billingaddress.street}
                          id="streetAddress"
                          placeholder="Street Address"
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleBillingAddress}
                          name="street2"
                          value={billingaddress.street2}
                          id="other"
                          placeholder="Apt/Suite/Other"
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <select
                          className="custom-select form-control d-block w-100"
                          id="state"
                          value={billingaddress.state}
                          onChange={handleBillingAddress}
                          name="state"
                          required
                        >
                          <option value="">State/Province</option>
                          {stateData.map(state => (
                            <option value={state.isoCode}>{state.name}</option>
                          ))}
                        </select>
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          onChange={handleBillingAddress}
                          name="city"
                          value={billingaddress.city}
                          id="city"
                          placeholder="City"
                        />
                        {/* <select
                          className="custom-select form-control d-block w-100"
                          id="city"
                          value={billingaddress.city}
                          onChange={handleBillingAddress}
                          name="city"
                          required
                        >
                          <option value="">Select City</option>
                          {BillingCityData.map(city => (
                            <option value={city.name}>{city.name}</option>
                          ))}
                        </select> */}
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="postalcode"
                          value={billingaddress.postalCode}
                          onChange={handleBillingAddress}
                          name="postalCode"
                          placeholder="Zip/Postal Code"
                          required
                        />
                      </div>
                      <div className="col-md-6 mb-3">
                        <input
                          type="text"
                          required
                          onChange={handleBillingAddress}
                          value={billingaddress.contact}
                          className="form-control"
                          id="contact"
                          name="contact"
                          placeholder="Contact"
                        />
                      </div>
                    </div>
                  </div>
                )}
                <div className="contact-info mb-4">
                  <div className="contact-info-heading fs-5 pb-4 fw-bolder">Shipping Charges</div>

                  <div className="row">
                    <label className="input">
                      <div className="input__content">
                        <div className="input__text">
                          <span className="input__title fs-5">
                            {!checkUpsellProduct
                              ? subTotalPrice > 0 && (addressDetails.state == 'AK' || addressDetails.state == 'HI')
                                ? 'Standard Shipping'
                                : 'Free Shipping'
                              : 'Free Shipping'}
                          </span>
                          {/* //standard shipping */}
                        </div>
                        <strong className="input__price">
                          $
                          {!checkUpsellProduct
                            ? subTotalPrice > 0 && (addressDetails.state == 'AK' || addressDetails.state == 'HI')
                              ? priceDetails.shipping.toFixed(2)
                              : '0.00'
                            : '0.00'}
                        </strong>
                      </div>
                    </label>
                  </div>
                </div>
                <div className="contact-info">
                  {/* <div className="contact-info-heading fs-5 pb-4 fw-bolder">Payment Method</div> */}
                  <div className="row paypal">
                    <PayPalScriptProvider
                      options={{
                        disableFunding: 'card,paylater',
                        components: 'buttons',
                        currency: 'USD',
                        intent: 'authorize',
                        clientId: 'AeYxYBqw9_RUWT3xB-D2px5bCsrKyr5Ag5SkcdxRAY19vfk_-9oQrI9IFuTQMTH020FGYDM-CkE6yzzX',
                      }}
                    >
                      <PayPalButtons
                        createOrder={createOrder}
                        onApprove={onApprove}
                        onClick={ValidationForm}
                        disabled={productInCart.length == 0}
                        style={{ color: 'gold', layout: 'vertical', tagline: false }}
                      />
                    </PayPalScriptProvider>
                  </div>
                  <div className="row ">
                    <div className="payment-info active py-4">
                      <div className="input__content-d d-flex justify-content-between">
                        <div className="gh">
                          {/* <input className="form-check-input" type="radio" name="cardPayment" checked /> */}
                          <label className="fw-bolder ps-2">Pay with Credit Card</label>
                        </div>
                        {/* <ul className="list-unstyled d-flex gap-2">
                          <li>
                            <img className="img-responsive pay-img" src="content/images/visacard.png" />
                          </li>
                        </ul> */}
                      </div>

                      <div className="row ps-4 pe-4">
                        {errorMessage && (
                          <div style={{ color: 'red' }}>
                            <p>{errorMessage}</p>
                          </div>
                        )}
                        <div className="col-md-12 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            name="number"
                            id="cardNumber"
                            value={cardDetails.number}
                            minLength={cardLength}
                            maxLength={cardLength}
                            onChange={handleCardDetails}
                            placeholder="Card number"
                            required
                          />
                        </div>

                        <div className="col-md-12 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleCardDetails}
                            name="first_name"
                            value={cardDetails.first_name}
                            id="firstName"
                            placeholder="Name on card"
                            required
                          />
                        </div>
                        {/* <div className="col-md-6 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            onChange={handleCardDetails}
                            name="last_name"
                            value={cardDetails.last_name}
                            id="lastName"
                            placeholder="Last Name"
                            required
                          />
                        </div> */}
                        <div className="col-md-6 mb-3">
                          <input
                            type="text"
                            className="form-control"
                            value={cardDetails.month}
                            name="month"
                            id="expMonth"
                            placeholder="Expiration Date(MM/YY)"
                            onChange={handleCardDetails}
                            required
                          />
                        </div>
                        <div className="col-md-6 mb-3">
                          <input
                            type="password"
                            className="form-control"
                            name="verification_value"
                            value={cardDetails.verification_value}
                            minLength={cvvLength}
                            maxLength={cvvLength}
                            id="cardCode"
                            placeholder="Card Security Code"
                            onChange={handleCardDetails}
                            required
                          />
                        </div>

                        {/* <div className="col-md-3 mb-3">
                          <input
                            type="text"
                            maxLength={4}
                            minLength={2}
                            value={cardDetails.year}
                            className="form-control"
                            name="year"
                            id="expYear"
                            placeholder="YYYY"
                            onChange={handleCardDetails}
                            required
                          />
                        </div> */}
                      </div>
                    </div>
                    {/* PAYPAL PAYMENT DISABLE */}
                    {/* <div className="payment-info py-3">
                      <div className="input__content-d d-flex justify-content-between">
                        <div className="gh">
                          <input className="form-check-input" type="radio" name="payPal" />
                          <label className="fw-bolder ps-2">
                            <img className="img-responsive" src="content/images/paypal.png" />
                          </label>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {productInCart.length > 0 && (
                    <div className="payment-method lg:flex d-flex align-items-center gap-3">
                      <div className=" col text-end dwidth flex-grow w-full lg:flex-grow">
                        <button
                          className="btn btn-primary mt-0 button-width content-btn me-2 w-100"
                          disabled={errorMessage != '' ? true : false}
                        >
                          Pay ${grandTotal}
                        </button>
                      </div>
                      {/* <div className="col ">
                        <Link to="/cart" className="lg:order-1">
                          Return to Cart
                        </Link>
                      </div> */}
                    </div>
                  )}
                </div>
                {/* <div className="col-12 pt-3 pb-0 hide-desktop">
                  <ul className="list-unstyled d-flex gap-2 justify-content-center">
                    <li>
                      <img className="img-responsive" src="content/images/paymnt.png" />
                    </li>
                    <li>
                      <img className="img-responsive" src="content/images/paymnt2.png" />
                    </li>
                    <li>
                      <img className="img-responsive" src="content/images/paymnt3.png" />
                    </li>
                  </ul>
                </div> */}
              </div>
              <div className="col-xl-4 col-md-12 flex-shrink w-full bg-gray-200 lg:flex-grow lg:pt-8 lg:px-8 sm:bg-white">
                <div className="contact-info mb-4 h-100">
                  <div className="accordion accordion-flush" id="accordionExample">
                    <div className="accordion-item pb-3">
                      <h2 className="accordion-header" id="headingOne">
                        <button
                          className="accordion-button fs-5"
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                          aria-expanded="true"
                          aria-controls="collapseOne"
                          id="hideorderdetails"
                        >
                          Order Review
                          <br />
                        </button>
                        <span className="d-block mt-2 fs-6 text-end px-2">
                          {productInCart.length} {productInCart.length <= 1 ? 'item' : 'items'} in cart
                        </span>
                      </h2>
                      <div id="collapseOne" className="accordion-collapse collapse show px-2" aria-labelledby="headingOne">
                        <div className="accordion-body" style={{ paddingLeft: 0, paddingRight: 0 }}>
                          {productInCart.length > 0 &&
                            productInCart.map(item => (
                              <div className="product mb-3">
                                <div className="row align-items-start" style={{ justifyContent: 'space-evenly' }}>
                                  <div className="col-md-3 col-sm-3 col-lg-3" style={{ paddingRight: 0, maxWidth: '80px' }}>
                                    <div className="product-product d-flex gap-3 fs-5">
                                      {' '}
                                      {/* <img src={item?.product?.productAssets?.length > 0 && getImageUrl(item.product.productAssets[0])} /> */}
                                    </div>
                                  </div>
                                  <div className="col-md-6 col-sm-6 col-lg-6" style={{ paddingRight: 0 }}>
                                    <div className="product-product gap-3">
                                      <span className="fs-6"> {item.product.name}</span>
                                      <br></br>
                                      <span style={{ color: '#000 !important', fontSize: '0.9rem' }}>
                                        Unit Price:{' '}
                                        {item.product.msrp && <span className="text-decoration-line-through">${item.product.msrp}</span>} $
                                        {item.product.price}
                                      </span>
                                      <br></br>
                                      <span
                                        style={{ color: '#000 !important', fontSize: '0.9rem' }}
                                        className="d-flex align-items-center gap-2"
                                      >
                                        Quantity:{' '}
                                        <div className="product-quantity">
                                          {item?.product?.productCategory?.name != 'quantity' ? (
                                            <div className="d-flex justify-content-start">
                                              <div className="input-group w-auto flex-nowrap">
                                                {/* <input
                                                  type="number"
                                                  step="1"
                                                  max="10"
                                                  value={quantityOfProduct[item.id] || item.quantity}
                                                  name="quantity"
                                                  className="quantity-field border-0 w-50 text-center"
                                                /> */}
                                                {quantityOfProduct[item.id] || item.quantity}
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="d-flex justify-content-between">
                                              <div className="input-group w-auto flex-nowrap">
                                                <input
                                                  type="button"
                                                  value="-"
                                                  className="button-minus border rounded-circle  icon-shape icon-sm mx-1 "
                                                  data-field="quantity"
                                                  onClick={() => handleQuantityChange(item.id, -1, item.quantity)}
                                                />
                                                <input
                                                  type="number"
                                                  step="1"
                                                  max="10"
                                                  value={quantityOfProduct[item.id] || item.quantity}
                                                  name="quantity"
                                                  className="quantity-field border-0 text-center "
                                                />
                                                <input
                                                  type="button"
                                                  value="+"
                                                  className="button-plus border rounded-circle icon-shape icon-sm "
                                                  data-field="quantity"
                                                  onClick={() => handleQuantityChange(item.id, 1, item.quantity)}
                                                />
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col-md-2 col-sm-2 col-lg-2" style={{ alignSelf: 'end' }}>
                                    <div className="product-price">
                                      <p className="fw-bolder">${item.price * item.quantity}</p>
                                    </div>
                                  </div>
                                  <div className="col-md-1 col-sm-1 col-lg-1" style={{ alignSelf: 'center' }}>
                                    <div className="product-price">
                                      {/* <img
                                        src="content/images/close.png"
                                        onClick={() => {
                                          removeProduct(item.id);
                                        }}
                                      /> */}
                                      <span
                                        className="fs-5 fw-bolder"
                                        onClick={() => {
                                          removeProduct(item.id);
                                        }}
                                      >
                                        x
                                      </span>
                                    </div>
                                  </div>
                                </div>
                                <hr />
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {productInCart.length > 0 ? (
                    <div className="desktop-view">
                      <div className="accordion accordion-flush" id="accordionExample">
                        <div className="accordion-item">
                          <h2 className="accordion-header" id="billingHeading">
                            <button
                              className="accordion-button fs-5"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target="#billingSummary"
                              aria-expanded="true"
                              aria-controls="billingSummary"
                              id="billingDetails"
                            >
                              Billing Summary
                            </button>
                          </h2>
                          <div id="billingSummary" className="accordion-collapse collapse show px-2" aria-labelledby="billingHeading">
                            <div className="accordion-body" style={{ paddingLeft: 0, paddingRight: 0 }}>
                              <div className="row">
                                <div className="col-6 ">
                                  <p className="fs-6">Subtotal</p>
                                </div>
                                <div className="col-6">
                                  <p className="fs-6 fw-bold text-end">${subTotalPrice}</p>
                                </div>
                                <div className="col-6 ">
                                  <p className="fs-6">Shipping</p>
                                </div>
                                <div className="col-6">
                                  <p className="fs-6 fw-bold text-end">
                                    $
                                    {!checkUpsellProduct
                                      ? subTotalPrice > 0 && (addressDetails.state == 'AK' || addressDetails.state == 'HI')
                                        ? shipping.toFixed(2)
                                        : '0.00'
                                      : '0.00'}
                                  </p>
                                </div>
                                {/* <div className="col-6 ">
                              <p className="fs-6">Tax</p>
                            </div> */}
                                {/* <div className="col-6">
                              <p className="fs-6 fw-bold text-end">${tax.toFixed(2)}</p>
                            </div> */}
                              </div>
                              <hr className="mt-0 mb-0" />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row grey-bg">
                        <div className="col-6">
                          <p className="fs-6 fw-bold">Grand Total </p>
                        </div>
                        <div className="col-6">
                          <p className="fs-6 fw-bold text-end">${grandTotal}</p>
                        </div>
                        <div className="col-12">
                          {/* <div className="custom-control custom-checkbox">
                                <input type="checkbox" className="custom-control-input" id="same-address" />
                                <label className="custom-control-label fs-6">My billing and shipping address ar e the same</label>
                              </div> */}
                        </div>

                        {/* <div className="col-12 pt-3 pb-0 hide-mobile">
                          <ul className="list-unstyled d-flex gap-2 justify-content-center">
                            <li>
                              <img className="img-responsive" src="content/images/paymnt.png" />
                            </li>
                            <li>
                              <img className="img-responsive" src="content/images/paymnt2.png" />
                            </li>
                            <li>
                              <img className="img-responsive" src="content/images/paymnt3.png" />
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    </div>
                  ) : (
                    <div className="text-center">
                      No Item in cart
                      <br />
                      {hideItems ? (
                        <ScrollLink
                          style={{ background: 'none', border: 'none' }}
                          activeClass="active"
                          to="order"
                          spy={true}
                          smooth={true}
                          duration={700}
                        >
                          Keep shopping
                        </ScrollLink>
                      ) : (
                        <Link to="/">Keep shopping</Link>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default Checkout;
