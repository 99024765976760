import { getEntity, getEntities } from 'app/entities/product/product.reducer';

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { Button, Row, Col, Card } from 'reactstrap';
import { Translate } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { getSession } from 'app/shared/reducers/authentication';
import { AUTHORITIES } from 'app/config/constants';

export const ProductDetailsView = () => {
  const dispatch = useAppDispatch();

  const { id } = useParams<'id'>();
  const getAllEntities = () => {
    dispatch(
      getEntities({
        query: `id.in=${id}`,
      })
    );
  };
  useEffect(() => {
    getAllEntities();
  }, [id]);

  var product = useAppSelector(state => state.product.entities);

  let entityFields = ['title', 'description', 'msrp', 'price', 'discountAmount', 'discountPercentage', 'name', 'sku'];

  return (
    <Card className="mx-5 mt-3">
      <Row>
        {product.map((product, i) =>
          Object.entries(product).map(([key, value]) => {
            if (entityFields.includes(key)) {
              if (value) {
                return (
                  <Col lg="4" md="12">
                    <dl className="shadow-sm">
                      <dt className="bg-primary bg-opacity-10 p-2 ps-3">
                        <span id={`${key}`}>{key as string}</span>
                      </dt>
                      <dd className="p-2 ps-3">{value as string}</dd>
                    </dl>
                  </Col>
                );
              } else {
                return;
              }
            }
          })
        )}
      </Row>
      <Button tag={Link} id="cancel-save" className="rounded-5" to="/" outline color="dark">
        <FontAwesomeIcon icon="arrow-left" />
        &nbsp;
        <span className="d-none d-md-inline">Back</span>
      </Button>
    </Card>
  );
};

export default ProductDetailsView;
