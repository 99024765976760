import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import ProductAsset from './product-asset';
import ProductAssetDetail from './product-asset-detail';
import ProductAssetUpdate from './product-asset-update';
import ProductAssetDeleteDialog from './product-asset-delete-dialog';

const ProductAssetRoutes = () => (
  <ErrorBoundaryRoutes>
    <Route index element={<ProductAsset />} />
    <Route path="new" element={<ProductAssetUpdate />} />
    <Route path=":id">
      <Route index element={<ProductAssetDetail />} />
      <Route path="edit" element={<ProductAssetUpdate />} />
      <Route path="delete" element={<ProductAssetDeleteDialog />} />
    </Route>
  </ErrorBoundaryRoutes>
);

export default ProductAssetRoutes;
