export enum OrderStatus {
  CONFIRMED = 'CONFIRMED',

  NEW = 'NEW',

  DELIVERED = 'DELIVERED',

  PENDING = 'PENDING',

  PROCESSING = 'PROCESSING',

  MODIFIED = 'MODIFIED',

  SHIPPED = 'SHIPPED',

  REFUNDED = 'REFUNDED',

  BACK_ORDERED = 'BACK_ORDERED',

  RETURN_STARTED = 'RETURN_STARTED',

  RETURNED = 'RETURNED',

  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',

  CANCELLED = 'CANCELLED',
}
