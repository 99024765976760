import product from 'app/entities/product/product.reducer';
import productCategory from 'app/entities/product-category/product-category.reducer';
import payment from 'app/entities/payment/payment.reducer';
import address from 'app/entities/address/address.reducer';
import cart from 'app/entities/cart/cart.reducer';
import paymentMethod from 'app/entities/payment-method/payment-method.reducer';
import order from 'app/entities/order/order.reducer';
import productInCart from 'app/entities/product-in-cart/product-in-cart.reducer';
import productAsset from 'app/entities/product-asset/product-asset.reducer';
/* jhipster-needle-add-reducer-import - JHipster will add reducer here */

const entitiesReducers = {
  product,
  productCategory,
  payment,
  address,
  cart,
  paymentMethod,
  order,
  productInCart,
  productAsset,
  /* jhipster-needle-add-reducer-combine - JHipster will add reducer here */
};

export default entitiesReducers;
