import React from 'react';
import { Route } from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import Product from './product';
import ProductCategory from './product-category';
import Payment from './payment';
import Address from './address';
import Cart from './cart';
import PaymentMethod from './payment-method';
import Order from './order';
import ProductInCart from './product-in-cart';
import ProductAsset from './product-asset';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="product/*" element={<Product />} />
        <Route path="product-category/*" element={<ProductCategory />} />
        <Route path="payment/*" element={<Payment />} />
        <Route path="address/*" element={<Address />} />
        <Route path="cart/*" element={<Cart />} />
        <Route path="payment-method/*" element={<PaymentMethod />} />
        <Route path="order/*" element={<Order />} />
        <Route path="product-in-cart/*" element={<ProductInCart />} />
        <Route path="product-asset/*" element={<ProductAsset />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
