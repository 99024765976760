import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Row, Col, FormText } from 'reactstrap';
import { isNumber, Translate, translate, ValidatedField, ValidatedForm } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IProduct } from 'app/shared/model/product.model';
import { getEntities as getProducts } from 'app/entities/product/product.reducer';
import { IPayment } from 'app/shared/model/payment.model';
import { getEntities as getPayments } from 'app/entities/payment/payment.reducer';
import { ICart } from 'app/shared/model/cart.model';
import { getEntities as getCarts } from 'app/entities/cart/cart.reducer';
import { IOrder } from 'app/shared/model/order.model';
import { getEntities as getOrders } from 'app/entities/order/order.reducer';
import { IProductInCart } from 'app/shared/model/product-in-cart.model';
import { getEntity, updateEntity, createEntity, reset } from './product-in-cart.reducer';

export const ProductInCartUpdate = () => {
  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const { id } = useParams<'id'>();
  const isNew = id === undefined;

  const products = useAppSelector(state => state.product.entities);
  const payments = useAppSelector(state => state.payment.entities);
  const carts = useAppSelector(state => state.cart.entities);
  const orders = useAppSelector(state => state.order.entities);
  const productInCartEntity = useAppSelector(state => state.productInCart.entity);
  const loading = useAppSelector(state => state.productInCart.loading);
  const updating = useAppSelector(state => state.productInCart.updating);
  const updateSuccess = useAppSelector(state => state.productInCart.updateSuccess);

  const handleClose = () => {
    navigate('/product-in-cart' + location.search);
  };

  useEffect(() => {
    if (isNew) {
      dispatch(reset());
    } else {
      dispatch(getEntity(id));
    }

    dispatch(getProducts({}));
    dispatch(getPayments({}));
    dispatch(getCarts({}));
    dispatch(getOrders({}));
  }, []);

  useEffect(() => {
    if (updateSuccess) {
      handleClose();
    }
  }, [updateSuccess]);

  const saveEntity = values => {
    const entity = {
      ...productInCartEntity,
      ...values,
      product: products.find(it => it.id.toString() === values.product.toString()),
      payment: payments.find(it => it.id.toString() === values.payment.toString()),
      cart: carts.find(it => it.id.toString() === values.cart.toString()),
      order: orders.find(it => it.id.toString() === values.order.toString()),
    };

    if (isNew) {
      dispatch(createEntity(entity));
    } else {
      dispatch(updateEntity(entity));
    }
  };

  const defaultValues = () =>
    isNew
      ? {}
      : {
          ...productInCartEntity,
          product: productInCartEntity?.product?.id,
          payment: productInCartEntity?.payment?.id,
          cart: productInCartEntity?.cart?.id,
          order: productInCartEntity?.order?.id,
        };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="8">
          <h2 id="mystoreApp.productInCart.home.createOrEditLabel" data-cy="ProductInCartCreateUpdateHeading">
            <Translate contentKey="mystoreApp.productInCart.home.createOrEditLabel">Create or edit a ProductInCart</Translate>
          </h2>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md="8">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <ValidatedForm defaultValues={defaultValues()} onSubmit={saveEntity}>
              {!isNew ? (
                <ValidatedField
                  name="id"
                  required
                  readOnly
                  id="product-in-cart-id"
                  label={translate('global.field.id')}
                  validate={{ required: true }}
                />
              ) : null}
              <ValidatedField
                label={translate('mystoreApp.productInCart.price')}
                id="product-in-cart-price"
                name="price"
                data-cy="price"
                type="text"
              />
              <ValidatedField
                label={translate('mystoreApp.productInCart.quantity')}
                id="product-in-cart-quantity"
                name="quantity"
                data-cy="quantity"
                type="text"
              />
              <ValidatedField
                label={translate('mystoreApp.productInCart.discount')}
                id="product-in-cart-discount"
                name="discount"
                data-cy="discount"
                type="text"
              />
              <ValidatedField
                label={translate('mystoreApp.productInCart.discountCodes')}
                id="product-in-cart-discountCodes"
                name="discountCodes"
                data-cy="discountCodes"
                type="text"
              />
              <ValidatedField
                id="product-in-cart-product"
                name="product"
                data-cy="product"
                label={translate('mystoreApp.productInCart.product')}
                type="select"
              >
                <option value="" key="0" />
                {products
                  ? products.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="product-in-cart-payment"
                name="payment"
                data-cy="payment"
                label={translate('mystoreApp.productInCart.payment')}
                type="select"
              >
                <option value="" key="0" />
                {payments
                  ? payments.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="product-in-cart-cart"
                name="cart"
                data-cy="cart"
                label={translate('mystoreApp.productInCart.cart')}
                type="select"
              >
                <option value="" key="0" />
                {carts
                  ? carts.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <ValidatedField
                id="product-in-cart-order"
                name="order"
                data-cy="order"
                label={translate('mystoreApp.productInCart.order')}
                type="select"
              >
                <option value="" key="0" />
                {orders
                  ? orders.map(otherEntity => (
                      <option value={otherEntity.id} key={otherEntity.id}>
                        {otherEntity.id}
                      </option>
                    ))
                  : null}
              </ValidatedField>
              <Button tag={Link} id="cancel-save" data-cy="entityCreateCancelButton" to="/product-in-cart" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">
                  <Translate contentKey="entity.action.back">Back</Translate>
                </span>
              </Button>
              &nbsp;
              <Button color="primary" id="save-entity" data-cy="entityCreateSaveButton" type="submit" disabled={updating}>
                <FontAwesomeIcon icon="save" />
                &nbsp;
                <Translate contentKey="entity.action.save">Save</Translate>
              </Button>
            </ValidatedForm>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProductInCartUpdate;
