const path = require('path');
const core_path = path.resolve(__dirname, '..');
console.log('core_path :: ', core_path);
import React, { useEffect, useState } from 'react';
import { CardGroup, Card, CardBody, CardTitle, Col, Row, CardText, Button, CardImg } from 'reactstrap';
import { getEntities } from '../../../../../app/entities/product/product.reducer';
import { createEntity } from './product.reducer';
import { useAppDispatch, useAppSelector } from '../../../../../app/config/store';
import { overridePaginationStateWithQueryParams } from '../../../../../app/shared/util/entity-utils';
import { getSortState, JhiItemCount, JhiPagination, Translate } from 'react-jhipster';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from '../../../../../app/shared/util/pagination.constants';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { hasAnyAuthority } from '../../../../../app/shared/auth/private-route';
import { getSession } from '../../../../../app/shared/reducers/authentication';
import { AUTHORITIES } from '../../../../../app/config/constants';
// import ReactPixel from 'react-facebook-pixel';
// import ReactGA from 'react-ga';
import { AxiosResponse } from 'axios';
// import { IProductAsset } from '../../../../../app/shared/model/product-asset.model';

import { ADDProduct } from '../../shared/model/add-product.model';
import { getEntities as getCartDetails } from '../../../../../app/entities/product-in-cart/product-in-cart.reducer';

interface Props {
  cardCols?: string;
}

export const ProductListView = (props: Props) => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams<'id'>();
  const isNew = id === undefined;
  const dataList = useAppSelector(state => state.product.entities);
  const updateProductInCart = useAppSelector(state => state.addProduct?.updating);
  const [posthogFlag, setPosthogFlag] = useState('');
  const [cartId, setCartId] = useState('');
  const [hideItems, setHideItems] = useState(false);
  const [atcCountCheck, setAtcCountCheck] = useState(0);

  const addToCart = async productId => {
    try {
      // Storage.session.set('productId', productId);
      const productDetails = dataList.find(product => product.id === productId);
      const addProductDetails = {
        productId: productDetails.id,
        quantity: 1,
        discountCodes: productDetails.discountCodes || null,
      };

      // Function to ensure that the same product is not added to the cart again by clicking the "Buy Now" call-to-action.

      let existingCartID = localStorage.getItem('cartId');
      if (existingCartID) {
        let cartDetailsList: any = await dispatch(getCartDetails({ query: `cartId.equals=${existingCartID}` }));
        let productList = cartDetailsList?.payload?.data || [];
        let productExist = productList.some(item => item?.product?.id == productId);
        if (productExist) {
          navigate('/checkout');
          return;
        }
      }

      const { payload } = (await dispatch(createEntity(addProductDetails))) as any;
      if (payload['status']) {
        // ReactGA.event({
        //   category: productDetails.name,
        //   action: 'add to cart',
        //   value: productDetails.price,
        //   transport: 'xhr',
        // });
        // ReactPixel.track('AddToCart', { content_name: productDetails.name, value: `${productDetails.price}`, currency: 'USD' });

        const cartId = payload['data'];
        setCartId(cartId.id);
        setAtcCountCheck(atcCountCheck + 1);
        console.log(atcCountCheck);
        localStorage.setItem('cartId', cartId.id);
        if (hideItems) {
          const anchor = document.querySelector('#shipping') as any;
          anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
        } else {
          navigate('/checkout');
        }
      }
    } catch (error) {
      console.log('🚀 ~ file: ProductListView.tsx:44 ~ addToCart ~ error:', error);
    }
  };

  // const getImageUrl = imageObj => {
  //   if (imageObj && imageObj.path !== '') {
  //     return imageObj.path;
  //   } else {
  //     return 'content/images/driver_whiz-box.png';
  //   }
  // };

  useEffect(() => {
    if (isNew) {
      dispatch(getEntities({}));
    } else {
      dispatch(getEntities({ query: `id.in=${id}` }));
    }
  }, [dispatch, id, isNew]);

  let entityFields = ['title', 'msrp', 'price', 'discountAmount', 'discountPercentage'];

  return (
    <Card className="swp_products">
      {dataList.map((product, i) => (
        <Card key={i} className="text-decoration-none mb-3">
          <CardBody>
            <CardTitle tag="h5">{product?.title}</CardTitle>
            <CardText style={{ float: 'left', width: '78%', padding: '0px 0px 20px' }}>
              <strong>Description:</strong> {product?.description}
              <br></br>
              <strong>Price:</strong> ${product?.price}
              <div className="product_buttons" style={{ display: 'flex', marginTop: '10px' }}>
                {/* <Button className="learnmore" href="" target="" style={{ backgroundColor: '#fff', marginRight: '10px', color: '#1172c1' }}>
                  Learn More
                </Button> */}
                <div className="buynow_button">
                  <button
                    className="btn btn-primary"
                    onClick={() => addToCart(product.id)}
                    style={{ backgroundColor: '#fff', color: '#1172c1', marginRight: '10px' }}
                  >
                    Buy Now
                  </button>
                </div>
                {/* <Button className="download" href="" target="_blank" style={{ backgroundColor: '#fff', color: '#1172c1' }}>
                  Download now
                </Button> */}
              </div>
              {/* <div className="link-agree" style={{ float: 'left', margin: '10px 0 0' }}>
                By clicking “<b>BUY NOW</b>“, I agree to Site <a href="">Terms and Conditions</a> and <a href="">Privacy Policy</a>.
              </div> */}
            </CardText>

            {product?.productAssets[0]?.type == 'Image' && (
              <CardImg
                top
                width={124}
                height={155}
                src={product.productAssets[0].path}
                // src={product.productAsset?.length > 0 && getImageUrl(product.productAsset[0])}
                alt={product.productAssets[0].name}
                className="alignnoneimage"
                style={{ maxWidth: '124px', height: 'auto' }}
              />
            )}
          </CardBody>
        </Card>
      ))}
      {!dataList && dataList.length === 0 && (
        <div className="alert alert-warning">
          <h2>No Product found</h2>
        </div>
      )}
    </Card>
  );
};

export default ProductListView;
