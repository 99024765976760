import { IProduct } from 'app/shared/model/product.model';
import { AssetType } from 'app/shared/model/enumerations/asset-type.model';

export interface IProductAsset {
  id?: number;
  name?: string | null;
  path?: string | null;
  type?: AssetType | null;
  product?: IProduct | null;
}

export const defaultValue: Readonly<IProductAsset> = {};
