import ProductListView from './ProductListView';

import ProductcategoryListView from './ProductcategoryListView';

import React from 'react';
import { Route } from 'react-router-dom';
import { Row, Col } from 'reactstrap';

interface Props {
  cardCols?: string;
}

export const HomePage = (props: Props) => {
  return (
    <div>
      <h3>Our Products:</h3>
      <Row>
        {/* <Col className="col-md-3">
          <ProductcategoryListView />
        </Col> */}

        <Col className="col-md-12">
          <ProductListView cardCols={props.cardCols} />
        </Col>

        <span id="custom-components" />
      </Row>
    </div>
  );
};

export default HomePage;
