import '../../../../../../app/shared/layout/header/header.scss';

import React, { useState } from 'react';
import { Translate, Storage } from 'react-jhipster';
import { Navbar, Nav, NavbarToggler, Collapse, NavLink, NavItem } from 'reactstrap';
import LoadingBar from 'react-redux-loading-bar';
import { Link as ScrollLink } from 'react-scroll';
import { Home, Brand } from '../../../../../../app/shared/layout/header/header-components';
import { AdminMenu, EntitiesMenu, AccountMenu, LocaleMenu } from '../../../../../../app/shared/layout/menus';
import { useAppDispatch } from '../../../../../../app/config/store';
import { setLocale } from '../../../../../../app/shared/reducers/locale';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isOpenAPIEnabled: boolean;
  currentLocale: string;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const dispatch = useAppDispatch();
  const location = useLocation();
  const handleLocaleChange = event => {
    const langKey = event.target.value;
    Storage.session.set('locale', langKey);
    dispatch(setLocale(langKey));
  };

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">
          <Translate contentKey={`global.ribbon.${props.ribbonEnv}`} />
        </a>
      </div>
    ) : null;

  const nonAuthenticatedMenuItems = [
    { label: 'Home', href: '/' },
    { label: 'Admin', href: '/admin' },
    // { label: 'Support', href: '#' },
    // { label: 'Order Look Up', href: '#' },
    // { label: 'About Us', href: '#' },
    // { label: 'Legal', href: '#' },
    // { label: 'Charge', href: '#' },
  ];

  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <>
      {props.isAuthenticated ? (
        <>
          <header id="header">
            <nav data-cy="navbar" className="navbar shadow navbar-expand-md">
              <div className="container-fluid">
                {/* <Link className="navbar-brand" to="/">
                  <img src="content/images/logo.svg" />
                </Link> */}
                <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
                <Collapse isOpen={menuOpen} navbar>
                  <Nav id="header-tabs" className="ms-auto dashboard" navbar>
                    {props.isAuthenticated && !props.isAdmin && (
                      <NavItem>
                        <NavLink tag={Link} to="/order" className="d-flex align-items-center">
                          <FontAwesomeIcon icon="asterisk" />
                          <span>Orders</span>
                        </NavLink>
                      </NavItem>
                    )}
                    {props.isAuthenticated && props.isAdmin && <Home />}
                    {props.isAuthenticated && props.isAdmin && <EntitiesMenu />}
                    {props.isAuthenticated && props.isAdmin && <AdminMenu showOpenAPI={props.isOpenAPIEnabled} />}
                    <LocaleMenu currentLocale={props.currentLocale} onClick={handleLocaleChange} />
                    <AccountMenu isAuthenticated={props.isAuthenticated} />
                  </Nav>
                </Collapse>
              </div>
            </nav>
          </header>
        </>
      ) : (
        <header id="header">
          <div className="row">
            {/* Check if the current location is the login page */}
            {location.pathname !== '/login' && location.pathname !== '/checkout' && (
              <nav id="nav" className="nav-holder">
                <div className="menu" style={{ float: 'right' }}>
                  {nonAuthenticatedMenuItems.map((item, index) => (
                    <NavLink key={index} href={item.href} style={{ display: 'inline-block', margin: '0 10px' }}>
                      {item.label}
                    </NavLink>
                  ))}
                </div>
              </nav>
            )}
          </div>
        </header>
      )}
    </>
  );
};

export default Header;
