import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Button, Table } from 'reactstrap';
import { Translate, TextFormat, getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { ASC, DESC, ITEMS_PER_PAGE, SORT } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';
import { useAppDispatch, useAppSelector } from 'app/config/store';

import { IPaymentMethod } from 'app/shared/model/payment-method.model';
import { getEntities } from './payment-method.reducer';

export const PaymentMethod = () => {
  const dispatch = useAppDispatch();

  const location = useLocation();
  const navigate = useNavigate();

  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(location, ITEMS_PER_PAGE, 'id'), location.search)
  );

  const paymentMethodList = useAppSelector(state => state.paymentMethod.entities);
  const loading = useAppSelector(state => state.paymentMethod.loading);
  const totalItems = useAppSelector(state => state.paymentMethod.totalItems);

  const getAllEntities = () => {
    dispatch(
      getEntities({
        page: paginationState.activePage - 1,
        size: paginationState.itemsPerPage,
        sort: `${paginationState.sort},${paginationState.order}`,
      })
    );
  };

  const sortEntities = () => {
    getAllEntities();
    const endURL = `?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`;
    if (location.search !== endURL) {
      navigate(`${location.pathname}${endURL}`);
    }
  };

  useEffect(() => {
    sortEntities();
  }, [paginationState.activePage, paginationState.order, paginationState.sort]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const page = params.get('page');
    const sort = params.get(SORT);
    if (page && sort) {
      const sortSplit = sort.split(',');
      setPaginationState({
        ...paginationState,
        activePage: +page,
        sort: sortSplit[0],
        order: sortSplit[1],
      });
    }
  }, [location.search]);

  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === ASC ? DESC : ASC,
      sort: p,
    });
  };

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });

  const handleSyncList = () => {
    sortEntities();
  };

  return (
    <div>
      <h2 id="payment-method-heading" data-cy="PaymentMethodHeading">
        <Translate contentKey="mystoreApp.paymentMethod.home.title">Payment Methods</Translate>
        <div className="d-flex justify-content-end">
          <Button className="me-2" color="info" onClick={handleSyncList} disabled={loading}>
            <FontAwesomeIcon icon="sync" spin={loading} />{' '}
            <Translate contentKey="mystoreApp.paymentMethod.home.refreshListLabel">Refresh List</Translate>
          </Button>
          <Link to="/payment-method/new" className="btn btn-primary jh-create-entity" id="jh-create-entity" data-cy="entityCreateButton">
            <FontAwesomeIcon icon="plus" />
            &nbsp;
            <Translate contentKey="mystoreApp.paymentMethod.home.createLabel">Create new Payment Method</Translate>
          </Link>
        </div>
      </h2>
      <div className="table-responsive">
        {paymentMethodList && paymentMethodList.length > 0 ? (
          <Table responsive>
            <thead>
              <tr>
                <th className="hand" onClick={sort('id')}>
                  <Translate contentKey="mystoreApp.paymentMethod.id">ID</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('otpCode')}>
                  <Translate contentKey="mystoreApp.paymentMethod.otpCode">Otp Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('cvvCode')}>
                  <Translate contentKey="mystoreApp.paymentMethod.cvvCode">Cvv Code</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('paymentType')}>
                  <Translate contentKey="mystoreApp.paymentMethod.paymentType">Payment Type</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('lastUseDate')}>
                  <Translate contentKey="mystoreApp.paymentMethod.lastUseDate">Last Use Date</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('vaultToken')}>
                  <Translate contentKey="mystoreApp.paymentMethod.vaultToken">Vault Token</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('name')}>
                  <Translate contentKey="mystoreApp.paymentMethod.name">Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('authorization')}>
                  <Translate contentKey="mystoreApp.paymentMethod.authorization">Authorization</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('expirationDate')}>
                  <Translate contentKey="mystoreApp.paymentMethod.expirationDate">Expiration Date</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('billingName')}>
                  <Translate contentKey="mystoreApp.paymentMethod.billingName">Billing Name</Translate> <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('authorizationTimestamp')}>
                  <Translate contentKey="mystoreApp.paymentMethod.authorizationTimestamp">Authorization Timestamp</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('creditCardNumber')}>
                  <Translate contentKey="mystoreApp.paymentMethod.creditCardNumber">Credit Card Number</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('accountIdentifier')}>
                  <Translate contentKey="mystoreApp.paymentMethod.accountIdentifier">Account Identifier</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('authorizationAmount')}>
                  <Translate contentKey="mystoreApp.paymentMethod.authorizationAmount">Authorization Amount</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th className="hand" onClick={sort('creditCardLast5')}>
                  <Translate contentKey="mystoreApp.paymentMethod.creditCardLast5">Credit Card Last 5</Translate>{' '}
                  <FontAwesomeIcon icon="sort" />
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {paymentMethodList.map((paymentMethod, i) => (
                <tr key={`entity-${i}`} data-cy="entityTable">
                  <td>
                    <Button tag={Link} to={`/payment-method/${paymentMethod.id}`} color="link" size="sm">
                      {paymentMethod.id}
                    </Button>
                  </td>
                  <td>{paymentMethod.otpCode}</td>
                  <td>{paymentMethod.cvvCode}</td>
                  <td>
                    <Translate contentKey={`mystoreApp.PaymentType.${paymentMethod.paymentType}`} />
                  </td>
                  <td>
                    {paymentMethod.lastUseDate ? (
                      <TextFormat type="date" value={paymentMethod.lastUseDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{paymentMethod.vaultToken}</td>
                  <td>{paymentMethod.name}</td>
                  <td>{paymentMethod.authorization}</td>
                  <td>
                    {paymentMethod.expirationDate ? (
                      <TextFormat type="date" value={paymentMethod.expirationDate} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{paymentMethod.billingName}</td>
                  <td>
                    {paymentMethod.authorizationTimestamp ? (
                      <TextFormat type="date" value={paymentMethod.authorizationTimestamp} format={APP_LOCAL_DATE_FORMAT} />
                    ) : null}
                  </td>
                  <td>{paymentMethod.creditCardNumber}</td>
                  <td>{paymentMethod.accountIdentifier}</td>
                  <td>{paymentMethod.authorizationAmount}</td>
                  <td>{paymentMethod.creditCardLast5}</td>
                  <td className="text-end">
                    <div className="btn-group flex-btn-group-container">
                      <Button tag={Link} to={`/payment-method/${paymentMethod.id}`} color="info" size="sm" data-cy="entityDetailsButton">
                        <FontAwesomeIcon icon="eye" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.view">View</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/payment-method/${paymentMethod.id}/edit?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="primary"
                        size="sm"
                        data-cy="entityEditButton"
                      >
                        <FontAwesomeIcon icon="pencil-alt" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.edit">Edit</Translate>
                        </span>
                      </Button>
                      <Button
                        tag={Link}
                        to={`/payment-method/${paymentMethod.id}/delete?page=${paginationState.activePage}&sort=${paginationState.sort},${paginationState.order}`}
                        color="danger"
                        size="sm"
                        data-cy="entityDeleteButton"
                      >
                        <FontAwesomeIcon icon="trash" />{' '}
                        <span className="d-none d-md-inline">
                          <Translate contentKey="entity.action.delete">Delete</Translate>
                        </span>
                      </Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          !loading && (
            <div className="alert alert-warning">
              <Translate contentKey="mystoreApp.paymentMethod.home.notFound">No Payment Methods found</Translate>
            </div>
          )
        )}
      </div>
      {totalItems ? (
        <div className={paymentMethodList && paymentMethodList.length > 0 ? '' : 'd-none'}>
          <div className="justify-content-center d-flex">
            <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} i18nEnabled />
          </div>
          <div className="justify-content-center d-flex">
            <JhiPagination
              activePage={paginationState.activePage}
              onSelect={handlePagination}
              maxButtons={5}
              itemsPerPage={paginationState.itemsPerPage}
              totalItems={totalItems}
            />
          </div>
        </div>
      ) : (
        ''
      )}
    </div>
  );
};

export default PaymentMethod;
